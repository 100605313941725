import { render, staticRenderFns } from "./PageRenewalByYear.vue?vue&type=template&id=185d3ef8&scoped=true&"
import script from "./PageRenewalByYear.vue?vue&type=script&lang=js&"
export * from "./PageRenewalByYear.vue?vue&type=script&lang=js&"
import style0 from "./PageRenewalByYear.vue?vue&type=style&index=0&id=185d3ef8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185d3ef8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('185d3ef8')) {
      api.createRecord('185d3ef8', component.options)
    } else {
      api.reload('185d3ef8', component.options)
    }
    module.hot.accept("./PageRenewalByYear.vue?vue&type=template&id=185d3ef8&scoped=true&", function () {
      api.rerender('185d3ef8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/analysis/PageRenewalByYear.vue"
export default component.exports