var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { ref: "pdfContent" }, [
    _c(
      "div",
      { staticClass: "printable-container" },
      [
        _c(
          "div",
          { staticClass: "exclude-from-print" },
          [
            _c("v-toolbar", { attrs: { color: "secondary" } }, [
              _c("h2", { staticStyle: { color: "white" } }, [
                _vm._v("7 Days Report"),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "v-card",
          { staticClass: "pa-5" },
          [
            _c("div", { staticClass: "exclude-from-print" }, [_c("br")]),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "6" } }, [
                  _vm.startDate && _vm.endDate
                    ? _c("div", [
                        _c("div", { staticClass: "mx-3 section custom-box" }, [
                          !_vm.isLoadingUsers
                            ? _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("h3", [
                                    _vm._v("Total active subscribers"),
                                  ]),
                                  this.activeSubscribingCompanyByWeek
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          this.activeSubscribingCompanyByWeek,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              _c("h3", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.subscription_count
                                                  )
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("v-col", [
                  _c("div", { staticClass: "d-flex flex-row" }, [
                    _c(
                      "div",
                      { staticClass: "exclude-from-print" },
                      [
                        _c("v-text-field", {
                          staticStyle: { "border-radius": "4px 0px 0px 4px" },
                          attrs: {
                            dense: "",
                            outlined: "",
                            "prepend-inner-icon": "mdi-calendar",
                            label: "Start Date...",
                          },
                          on: {
                            "click:prepend-inner": function () {
                              _vm.isStartDate = true
                            },
                            click: function () {
                              _vm.isStartDate = true
                            },
                          },
                          model: {
                            value: _vm.filter.selectedStartDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "selectedStartDate", $$v)
                            },
                            expression: "filter.selectedStartDate",
                          },
                        }),
                        _c(
                          "v-dialog",
                          {
                            attrs: { height: "auto", width: "auto" },
                            model: {
                              value: _vm.isStartDate,
                              callback: function ($$v) {
                                _vm.isStartDate = $$v
                              },
                              expression: "isStartDate",
                            },
                          },
                          [
                            _c("v-card", { staticClass: "pa-3" }, [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("p", [_vm._v(" Start Date ")]),
                                _c(
                                  "div",
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        flat: "",
                                        landscape: true,
                                        reactive: true,
                                      },
                                      model: {
                                        value: _vm.filter.selectedStartDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "selectedStartDate",
                                            $$v
                                          )
                                        },
                                        expression: "filter.selectedStartDate",
                                      },
                                    }),
                                    _vm._t("footer", function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-end white",
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function () {
                                                    _vm.isStartDate = false
                                                    _vm.filter.selectedStartDate =
                                                      null
                                                  },
                                                },
                                              },
                                              [_vm._v(" Cancel ")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function () {
                                                    _vm.isStartDate = false
                                                  },
                                                },
                                              },
                                              [_vm._v(" Confirm ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "exclude-from-print" },
                      [
                        _c("v-text-field", {
                          staticStyle: { "border-radius": "0px" },
                          attrs: {
                            dense: "",
                            outlined: "",
                            "prepend-inner-icon": "mdi-calendar",
                            label: "End Date...",
                          },
                          on: {
                            "click:prepend-inner": function () {
                              _vm.isEndDate = true
                            },
                            click: function () {
                              _vm.isEndDate = true
                            },
                          },
                          model: {
                            value: _vm.filter.selectedEndDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "selectedEndDate", $$v)
                            },
                            expression: "filter.selectedEndDate",
                          },
                        }),
                        _c(
                          "v-dialog",
                          {
                            attrs: { height: "auto", width: "auto" },
                            model: {
                              value: _vm.isEndDate,
                              callback: function ($$v) {
                                _vm.isEndDate = $$v
                              },
                              expression: "isEndDate",
                            },
                          },
                          [
                            _c("v-card", { staticClass: "pa-3" }, [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("p", [_vm._v(" End Date ")]),
                                _c(
                                  "div",
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        flat: "",
                                        landscape: true,
                                        reactive: true,
                                      },
                                      model: {
                                        value: _vm.filter.selectedEndDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "selectedEndDate",
                                            $$v
                                          )
                                        },
                                        expression: "filter.selectedEndDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { attrs: { name: "footer" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end my-3" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", plain: "" },
                                        on: {
                                          click: function () {
                                            _vm.isEndDate = false
                                            _vm.filter.selectedEndDate = null
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function () {
                                            _vm.isEndDate = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Confirm ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "exclude-from-print" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "border-radius": "0px 4px 4px 0px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      color: "primary",
                                      loading: _vm.isLoadingFilter,
                                    },
                                    on: { click: _vm.filterDate },
                                  },
                                  [_vm._v(" Filter ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "border-radius": "4px 4px 4px 4px",
                                      height: "40px",
                                    },
                                    attrs: { color: "primary", outlined: "" },
                                    on: { click: _vm.generateReport },
                                  },
                                  [_vm._v(" Download ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm.startDate && _vm.endDate
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pr-5",
                            attrs: { cols: "12", sm: "6" },
                          },
                          [
                            _c("PageNewSales", {
                              attrs: {
                                startDate: _vm.startDate,
                                endDate: _vm.endDate,
                              },
                            }),
                            _c("hr", { staticClass: "my-4" }),
                            _c("PageRenewal", {
                              attrs: {
                                renewalYear: 2,
                                startDate: _vm.startDate,
                                endDate: _vm.endDate,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pr-5",
                            attrs: { cols: "12", sm: "6" },
                          },
                          [
                            _c("PageRenewal", {
                              attrs: {
                                renewalYear: 3,
                                startDate: _vm.startDate,
                                endDate: _vm.endDate,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      { staticClass: "no-data-card" },
                      [
                        _c("v-card-text", [
                          _c("h3", { staticClass: "no-data-message" }, [
                            _vm._v(
                              " Select start and end dates to view the report. "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }