var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoadingRenewalByYear
        ? _c("v-skeleton-loader", {
            ref: "skeleton",
            staticClass: "mt-4",
            attrs: { type: "table" },
          })
        : _vm._e(),
      this.renewalByYear
        ? _c(
            "div",
            _vm._l(this.renewalByYear, function (item, key) {
              return _c("div", { key: key }, [
                item.subscription_year == _vm.subscriptionYear
                  ? _c("div", [
                      _c("h3", [_vm._v("Year " + _vm._s(item.year))]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mt-3" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Renewal"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(
                              _vm._s(
                                item["Renewed - Year " + _vm.subscriptionYear]
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Total"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(_vm._s(item.total_count)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Percentage Achieved"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(
                              _vm._s(
                                _vm.calculatePercentageAchieved(
                                  item[
                                    "Renewed - Year " + _vm.subscriptionYear
                                  ],
                                  item.total_count
                                )
                              ) + "%"
                            ),
                          ]),
                        ]
                      ),
                      _c("table", { staticClass: "styled-table" }, [
                        _c("tr", [
                          _c("td", [_vm._v("Total Due")]),
                          _c("td", [_vm._v(_vm._s(item.total_count))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Renewed")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                item["Renewed - Year " + _vm.subscriptionYear]
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Confirmed but pending payment")]),
                          _c("td", [_vm._v(_vm._s(item["Confirmed"]))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Pending client decision")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                Number(item["Pending"]) + Number(item["N/A"])
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Cancelled")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                Number(item["Cancelled"]) +
                                  Number(item["Rejected"])
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("br"),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }