var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-2 section" },
      [
        _c("h2", [_vm._v("Sales")]),
        _vm._m(0),
        _vm.isLoadingSales
          ? _c("v-skeleton-loader", {
              ref: "skeleton",
              staticClass: "mt-4",
              attrs: { type: "table" },
            })
          : _vm._e(),
        this.totalSalesByEndDate
          ? _c(
              "div",
              _vm._l(this.totalSalesByEndDate, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("h3", [_vm._v("Year " + _vm._s(item.year))]),
                    _c("div", { staticClass: "d-flex justify-space-between" }, [
                      _c("p", { staticClass: "text-h7" }, [
                        _vm._v("New Sales in " + _vm._s(item.year)),
                      ]),
                      _c("p", { staticClass: "text-h7" }, [
                        _vm._v(_vm._s(item.total_sales)),
                      ]),
                    ]),
                    _c("PageTargetSales", {
                      attrs: {
                        label: "Target Sales in " + item.year,
                        storageKey: "targetSales" + item.year,
                        defaultTarget: 0,
                        newSales: item.total_sales,
                      },
                    }),
                    _vm.filteredMonthSales(item.year).length
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.filteredMonthSales(item.year),
                            function (salesItem, salesIndex) {
                              return _c("div", { key: salesIndex }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatMonthDate(salesItem.month)
                                        ) +
                                          " " +
                                          _vm._s(salesItem.year) +
                                          " New Sales"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(_vm._s(salesItem.total_sales)),
                                    ]),
                                  ]
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c("br"),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        this.salesByWeek
          ? _c(
              "div",
              _vm._l(this.salesByWeek, function (item, weekIndex) {
                return _c("div", { key: weekIndex }, [
                  _c("h3", [
                    _vm._v(
                      "Week: " +
                        _vm._s(_vm.formatDate(item.start_date)) +
                        " – " +
                        _vm._s(_vm.formatDate(item.end_date))
                    ),
                  ]),
                  _c("div", { staticClass: "d-flex justify-space-between" }, [
                    _c("p", { staticClass: "text-h7" }, [_vm._v("New Sales")]),
                    _c("p", { staticClass: "text-h7" }, [
                      _vm._v(_vm._s(item.total_sales)),
                    ]),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "exclude-from-print" }, [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }