var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoadingRenewalByWeek
        ? _c("v-skeleton-loader", {
            ref: "skeleton",
            staticClass: "mt-4",
            attrs: { type: "table" },
          })
        : _vm._e(),
      this.renewalByWeek
        ? _c(
            "div",
            _vm._l(this.renewalByWeek, function (item, key) {
              return _c("div", { key: key }, [
                item.end_date == _vm.formatDate(_vm.endDate) &&
                item.subscription_year == _vm.subscriptionYear
                  ? _c("div", [
                      _c("h3", [
                        _vm._v(
                          "Week: " +
                            _vm._s(_vm.formatLongDate(_vm.startDate)) +
                            " – " +
                            _vm._s(_vm.formatLongDate(_vm.endDate))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mt-3" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Renewal"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(
                              _vm._s(
                                item["Renewed - Year " + _vm.subscriptionYear]
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Total"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(_vm._s(item.total_count)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v("Percentage Achieved"),
                          ]),
                          _c("p", { staticClass: "text-h7" }, [
                            _vm._v(
                              _vm._s(
                                _vm.calculatePercentageAchieved(
                                  item[
                                    "Renewed - Year " + _vm.subscriptionYear
                                  ],
                                  item.total_count
                                )
                              ) + "%"
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }