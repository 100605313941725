var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-space-between align-items-center" },
      [
        _c("p", { staticClass: "text-h7" }, [_vm._v(_vm._s(_vm.label))]),
        !_vm.isEditing
          ? _c("input", {
              staticClass: "text-h7 text-right custom-input",
              attrs: { type: "text", readonly: "" },
              domProps: {
                value:
                  _vm.editedTargetSales !== null
                    ? _vm.editedTargetSales
                    : _vm.targetSales,
              },
              on: { click: _vm.startEditing },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editedTargetSales,
                  expression: "editedTargetSales",
                },
              ],
              staticClass: "text-h7 text-right custom-input",
              attrs: { type: "text" },
              domProps: { value: _vm.editedTargetSales },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.editedTargetSales = $event.target.value
                },
              },
            }),
      ]
    ),
    _vm.isEditing
      ? _c(
          "div",
          {
            staticClass: "d-flex justify-end mb-2",
            staticStyle: { "margin-top": "-10px" },
          },
          [
            _c(
              "v-btn",
              {
                staticClass: "mr-2",
                attrs: { small: "", color: "primary" },
                on: { click: _vm.saveChanges },
              },
              [_vm._v("Save")]
            ),
            _c(
              "v-btn",
              {
                attrs: { small: "", color: "default" },
                on: { click: _vm.cancelEditing },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "d-flex justify-space-between" }, [
      _c("p", { staticClass: "text-h7" }, [_vm._v("Percentage Achieved")]),
      _c("p", { staticClass: "text-h7" }, [
        _vm._v(
          _vm._s(
            _vm.calculatePercentageAchieved(_vm.newSales, _vm.targetSales)
          ) + "%"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }