var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.renewalYear == "2"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "mx-2 section" },
            [
              _c("h2", [_vm._v("Year 2 Renewals")]),
              _vm._m(0),
              _c("PageRenewalByYear", {
                attrs: {
                  subscriptionYear: 2,
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                },
              }),
              _c("br"),
              _c("PageRenewalByWeek", {
                attrs: {
                  subscriptionYear: 2,
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                },
              }),
              _c("br"),
              _c("PageRenewalAllTime", {
                attrs: { subscriptionYear: 2, endDate: _vm.endDate },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.renewalYear > "2"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "mx-2 section" },
            [
              _c("h2", [
                _vm._v("Year " + _vm._s(_vm.renewalYear) + " Renewals"),
              ]),
              _vm._m(1),
              _c("PageRenewalByWeek", {
                attrs: {
                  subscriptionYear: _vm.renewalYear,
                  startDate: _vm.startDate,
                  endDate: _vm.endDate,
                },
              }),
              _c("br"),
              _c("PageRenewalAllTime", {
                attrs: {
                  subscriptionYear: _vm.renewalYear,
                  endDate: _vm.endDate,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "exclude-from-print" }, [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "exclude-from-print" }, [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }